import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import * as System from "../../design-system"

const SecondPage = () => {
  return (
    <Layout>
      <SEO title="My Page" />

      <div
        style={{
          width: "240px",
          margin: "50px auto",
        }}
      >
        <System.Paragraph text="현재 신청내역은 " />

        <System.Paragraph text="개별적으로 문의를 해주시면" />
        <System.Paragraph text="<br />영업시간 기준 ‘12시간내에' 답변을 드리고 있습니다" />

        <System.Paragraph text="<br />아래 링크를 통하여 문의 남겨주시면 최대한 신속하게 답변 드리겠습니다" />
      </div>

      <div
        style={{
          width: "130px",
          margin: "50px auto",
        }}
      >
        <a href="https://harbor-school.channel.io" target="_blank">
          <Image filename="Action3.png" />
        </a>
      </div>

      {/* <Link to="/">Go back to the homepage</Link> */}
    </Layout>
  )
}

export default SecondPage
